<template>
  <div v-if="isLoading">loading</div>
  <max-player v-else ref="player" :options="options"/>
</template>

<script>
import {mapGetters} from "vuex";
import Hls from "hls.js";
import _ from "lodash";
export default {
  name: "Watch",
  props: ["currentEpisode", "backLink", "episode"],
  data() {
    return {
      isLoading: true,
      options: {
        mediaId: null,
        userId: null,
        theme: window.init.appColor,
        autoplay: true,
        screenshot: false,
        loop: false,
        mutex: true,
        airplay: false,
        theatreMode: true,
        highlight: [],
        goBack: this.closePage,
        contextmenu: [],
        startTime: 0,
        historyUrl: 'https://log.seeroo.mn/history'
      }
    }
  },

  computed: {
    ...mapGetters(['isLoadingEpisode', 'user']),
  },

  mounted() {
    this.initWatch();
  },

  methods: {
    async initWatch() {
      this.isLoading = true;
      await this.setPlayerInfo();
      this.isLoading = false;
    },

    async setPlayerInfo() {
      await this.setSource();
      await this.setDub();
      this.setTitle();
      this.setMask();
      this.setSubTitle();
      this.setAuthor();
      this.setStartTime();
      this.setSkip();
      this.setNext();
      this.setPrev();
      this.setOpEdHighlight();
      this.setVast();
      await this.setMediaId();
      this.isLoading = false;
    },

    iOS() {
      return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },

    replaceExt(vid) {
      let ext = vid.split(/[#?]/)[0].split('.').pop().trim();
      let isIOS = this.iOS();
      if (isIOS && (ext == 'wmx' || ext == 'max') && this.$app == 'wemax') {
        return vid.substr(0, vid.lastIndexOf(".")) + ".m3u8";
      }
      return vid;
      // return "https://www.dropbox.com/s/df2d2gf1dvnr5uj/Sample_1280x720_mp4.mp4";
    },

    getExt(vid) {
      let ext = vid.split(/[#?]/)[0].split('.').pop().trim();
      if (Hls.isSupported() && (ext == "m3u8" || ext == "wmx" || ext == "max")) {
        ext = 'hls';
      }
      return ext;
      // return 'mp4';
    },

    async setSource() {
      let qualities = []
      let currentIndex = 0;
      let has480 = false;
      let has720 = false;
      let has1080 = false;

      if (this.episode.vid1 !== null) {
        has480 = true;
        let vid = {
          url: this.replaceExt(this.episode.vid1),
          type: this.getExt(this.episode.vid1),
          name: '480 SD',
        };
        qualities.push(vid);
      }

      if (this.episode.vid2 !== null) {
        has720 = true
        let vid = {
          url: this.replaceExt(this.episode.vid2),
          type: this.getExt(this.episode.vid2),
          name: '720 HD',
        };
        qualities.push(vid);
      }

      if (this.episode.vid3 !== null) {
        has1080 = true;
        let vid = {
          url: this.replaceExt(this.episode.vid3),
          type: this.getExt(this.episode.vid3),
          name: '1080 QHD',
        };
        qualities.push(vid);
      }

      if (localStorage.getItem("quality") !== null) {
        let savedIndex = localStorage.getItem("quality");

        if (savedIndex.toString() === '0' && has480) {
          currentIndex = 0;
        }

        if (savedIndex.toString() === '1' && has720 && qualities.length >= 2) {
          currentIndex = 1;
        }

        if (savedIndex.toString() === '1' && has720 && qualities.length === 1) {
          currentIndex = 0;
        }

        if (savedIndex.toString() === '2' && has1080 && qualities.length === 3) {
          currentIndex = 2;
        }

        if (savedIndex.toString() === '2' && has1080 && qualities.length === 2) {
          currentIndex = 1;
        }

        if (savedIndex.toString() === '2' && has1080 && qualities.length === 1) {
          currentIndex = 0;
        }

        // if (savedIndex.toString() === '0' && this.episode.vid1 == null) {
        //     currentIndex = 1;
        // }
        //
        // if (savedIndex.toString() === '1' && this.episode.vid2 !== null) {
        //     currentIndex = 1;
        // }
        //
        // if (savedIndex.toString() === '1' && this.episode.vid2 == null) {
        //     currentIndex = 2;
        // }
        //
        // if (savedIndex.toString() === '2' && this.episode.vid3 !== null) {
        //     currentIndex = 2;
        // }
        //
        // if (savedIndex.toString() === '2' && this.episode.vid3 == null) {
        //     currentIndex = 0;
        // }
      }

      this.options.video = {
        quality: qualities,
        defaultQuality: currentIndex,
        thumbnails: this.episode.v_thumb
      }
    },

    loadDubDefaultOption(setStorage) {
      this.episode.dubs.some((item) => {
        if (item.key == 'mn') {
          this.options.audio = item.url;
          if (setStorage) {
            localStorage.setItem('sound', 'mn');
          }
          return 'mn';
        }

        if (item.key == 'en') {
          this.options.audio = item.url;
          if (setStorage) {
            localStorage.setItem('sound', 'en');
          }
          return 'en';
        }
      })
    },

    async setDub() {
      // if (this.episode.dubs) {
      //   this.options.dubs = this.episode.dubs;
      //   if (localStorage.getItem('sound')) {
      //     let issetInDubs = this.episode.dubs.find(item => item.key == localStorage.getItem('sound'));
      //     if (issetInDubs) {
      //       this.episode.dubs.forEach((item) => {
      //         if (item.key == localStorage.getItem('sound')) {
      //           this.options.audio = item.url
      //         }
      //       })
      //     } else {
      //       this.loadDubDefaultOption(false)
      //     }
      //   } else {
      //     this.loadDubDefaultOption(true);
      //   }
      // }

      console.log("audio:", this.options.audio);
    },

    setTitle() {
      this.options.title = {};
      this.options.title.main = this.episode.atitle;
      if (this.episode.title != null) {
        this.options.title.sub = this.episode.title + " | " + this.episode.number + "-р анги";
      } else {
        this.options.title.sub = this.episode.number + "-р анги";
      }
    },

    setNext() {
      if (this.episode.next != null) {
        let next = this.episode.next;
        this.options.next = {
          handler: this.gotoNext,
          // thumb: this.$helper.fixImagePath(next.image),
          title: next.title == null ? next.number + "-р анги" : next.title + " | " + next.number + "-р анги",
          description: next.description
        };
      }
    },

    setPrev() {
      if (this.episode.prev != null) {
        let prev = this.episode.prev;
        this.options.prev = {
          handler: this.gotoPrev,
          // thumb: this.$helper.fixImagePath(prev.image),
          title: prev.title == null ? prev.number + "-р анги" : prev.title + " | " + prev.number + "-р анги",
          description: prev.description
        };
      }
    },

    setAuthor() {
      if (this.episode.tr_name != null) {
        this.options.author = this.episode.tr_name;
      }
      this.options.userId = this.user.id;
    },

    setSkip() {
      if (this.episode.op_start !== null) {
        let startSec = this.minToSec(this.episode.op_start);
        let endSec = startSec + 90;
        if (this.episode.op_end != null) {
          endSec = this.minToSec(this.episode.op_end);
        }

        this.options.skipOp = {
          start: startSec,
          end: endSec
        };
      }

      if (this.episode.ed_start !== null) {
        let startSec = this.minToSec(this.episode.ed_start);
        let endSec = startSec + 90;
        if (this.episode.ed_end != null) {
          endSec = this.minToSec(this.episode.ed_end);
        }

        this.options.skipEd = {
          start: startSec,
          end: endSec
        };
      }
    },

    randomNumber(minimum, maximum) {
      return Math.round(Math.random() * (maximum - minimum) + minimum);
    },

    setMask() {
      if (this.episode.is_mask && this.user != null) {
        this.options.stamp = {
          user: this.user.id,
          x: this.randomNumber(0, 100),
          y: this.randomNumber(0, 100)
        };
      }
    },

    minToSec(val) {
      if (val == null) {
        return;
      }

      let valArr = val.toString().split(":")
      if (valArr.length === 2) {
        return ((parseInt(valArr[0]) * 60) + parseInt(valArr[1]));
      } else if (valArr.length === 3) {
        return ((parseInt(valArr[0]) * 3600) + parseInt(valArr[1]) * 60 + parseInt(valArr[2]));
      } else {
        return parseInt(valArr[0]);
      }
    },

    gotoNext() {
      if (this.episode.next !== null) {
        this.$router.push(`/watch/${this.episode.anime_id}/${this.episode.next.id}`);
        // this.getEpisode(this.episode.next.id);
        this.$store.dispatch('switchEpisode');
        this.$store.dispatch('getEpisodeDetail', {vm: this, episodeID: this.episode.next.id});
      }
    },

    gotoPrev() {
      if (this.episode.prev !== null) {
        this.$router.push(`/watch/${this.episode.anime_id}/${this.episode.prev.id}`);
        this.$store.dispatch('switchEpisode');
        this.$store.dispatch('getEpisodeDetail', {vm: this, episodeID: this.episode.prev.id});
        // this.getEpisode(this.episode.prev.id);
      }
    },

    setOpEdHighlight() {
      if (this.episode.op_start_sec != null) {
        let item = {
          text: 'OP эхлэх',
          time: this.episode.op_start_sec,
        }
        this.options.highlight.push(item)
      }

      if (this.episode.op_end_sec != null) {
        let item = {
          text: 'OP дуусах',
          time: this.episode.op_end_sec,
        }
        this.options.highlight.push(item)
      }

      if (this.episode.ed_start_sec != null) {
        let item = {
          text: 'ED эхлэх',
          time: this.episode.ed_start_sec,
        }
        this.options.highlight.push(item)
      }

      if (this.episode.ed_end_sec != null) {
        let item = {
          text: 'ED дуусах',
          time: this.episode.ed_end_sec,
        }
        this.options.highlight.push(item)
      }
    },

    setSubTitle() {
      if (this.episode.subs.length > 0) {
        let subs = _.cloneDeep(this.episode.subs);
        console.log(subs);

        subs = subs.map(item => {
          if (item.isRaw == 0) {
            item.key = [6, 13, 14, 18, 19, 26, 30, 38, 42, 45, 48, 53, 62, 63, 67, 76];
            item.iv = [9, 15, 19, 24, 32, 43, 44, 46, 48, 49, 59, 64, 71, 83, 87, 93];
          }

          if (localStorage.getItem('sub') && localStorage.getItem('sub') == item.code) {
            this.options.subtitle = item;
          }
          return item;
        })
        this.options.subs = subs;

        if (this.options.subtitle == null && localStorage.getItem('sub') && localStorage.getItem('sub') == 'mn') {
          let issetEnSub = subs.find(s => s.code == 'en');
          if (issetEnSub) {
            this.options.subtitle = issetEnSub;
          }
        }
      }
      // this.options.subtitle = this.options.subs[0];
    },

    setVast() {
      if (this.episode.vast !== null && typeof this.episode.vast != "undefined") {
        this.options.vast = {
          url: this.episode.vast.url,
          link: this.episode.vast.link,
          title: this.episode.vast.title,
          thumb: this.episode.vast.thumb,
        };
      }
    },

    setStartTime() {
      this.options.startTime = this.episode.startTime ? this.episode.startTime : 0;
    },

    async setMediaId() {
      this.options.media = this.episode.anime_id;
      this.options.episode = this.episode.id;
    },

    closePage() {
      this.$router.push('/theatre/detail/' + this.episode.anime_id);
    },
  }
}
</script>

<style scoped>

</style>